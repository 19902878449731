import {formFieldValidator, formSubmitValidator, removeFormMessage} from './utils/form-validator.js';
import { showLoader } from "./utils/helpers.js";

const formElement = document.querySelector('form');
const formFieldElements = document.querySelectorAll('form input');


formFieldElements.forEach((formFieldElement) => {
    formFieldElement.addEventListener('blur', () => {
        formFieldValidator(formFieldElement);
    })
})

formElement.addEventListener('submit', (e) => {
    formSubmitValidator(e);
    if (!e.defaultPrevented) {
        e.submitter.disabled = true;
        showLoader(e.submitter);
    }
});

removeFormMessage(formElement, true);