import { aria, openDialog } from "../dialog.js";
import { showError } from "./form-validator.js";

export const urlPrefix = `${window.location.origin}/api`

/**
 * Send datas to the server
 * @param {string} url The request url
 * @param {object|Array} data - The datas to sends
 * @param {string} method The request method
 */
export async function httPost(url, data, method = "POST", onErrorCallback=null) {
    const headers = {
        'accept': 'application/json',
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf_token"]').getAttribute('content')
    }
    if (!(data instanceof FormData)) {
        data = JSON.stringify(data);
        headers['Content-Type'] = 'application/json';
    }

    try {
        const response = await fetch(url, {
                headers: headers,
                method: method,
                body: data,
        })
        if (!response.ok) {
            const result = await response.json();
            throw { statusCode: response.status, message: result.message, fieldsErrors: result.errors};
        }
        return await response.json();;

    } catch(error) {
        console.error(error)
        if (onErrorCallback) {
            onErrorCallback.call(null, error);
        } else {
            throw error;
        }
    }
}

/**
 * Fetch datas from api endpoint
 * @param {string} url - The endpoint url
 * @return {Promise} The loaded datas in json format
 */
export async function httpGet(url, onErrorCallback=null) {
    try {
        const response = await fetch(url, {
            headers: {
                accept: 'application/json',
            },
        });
        const result = await response.json();
        if (response.ok) {
            return result;
        }

        throw { statusCode: response.status, message: result.message};

    } catch (error) {
        console.error(error);
        if (onErrorCallback) {
            onErrorCallback.call(null, error);
        } else {
            throw error;
        }
    }
}

/**
 * Show a loader
 * @param {HTMLElement} element - The loader container
 */
export function showLoader(element) {
    element.classList.add('loader', 'spinner', 'loader-only');
}

/**
 * Remove a loader
 * @param {HTMLElement} element - The loader container
 */
export function removeLoader(element) {
    if (element.classList.contains('loader')) {
        element.classList.remove('loader', 'spinner');
    }
}

/**
 * Show a message inside a container
 * @param {string} message - The message text to show
 * @param {HTMLElement} messageWrapperElement - The message container
 * @param {'error'|'success'} type - The type of the message
 * @param {'notification'|'alert'} as -  Show the message as notification or alert
 */
export function showMessage(message, messageWrapperElement, type = "success", as = 'alert') {
    let messageElement = messageWrapperElement.querySelector('p.message:not(.dialog p.message, .table-wrapper .message)');
    
    if (!messageElement) {
        messageElement = document.createElement('p');
        messageElement.appendChild(document.createElement('span'));
        messageElement.className = "message";
        /**
         * @type {HTMLTemplateElement}
        */
       const closeButtonTemplate = document.querySelector('#close-button-template');
       messageElement.appendChild(document.importNode(closeButtonTemplate.content, true));
       messageWrapperElement.appendChild(messageElement);
    } else {
        messageElement.classList.remove('active', 'hidden');
    }
    if (type === "error") {
        messageElement.classList.add('error');
    } else {
        messageElement.classList.remove('error');
    }
    
    messageElement.classList.add(as);
    if (as === "notification") {
        setTimeout(() => {
            messageElement.classList.add('active');
            messageElement.querySelector('button').classList.add('notification-close-button');
        }, 10);
    }

    messageElement.firstElementChild.textContent = message;
}

export function removeMessage(wrapperElement) {
    const messageElement = wrapperElement.querySelector('p.message:not(.dialog p.message)');
    if (messageElement) {
        messageElement.remove();
    }
}

/**
 * Display network error message
 * @param {HTMLElement} messageWrapperElement - The container of error message
 * @param {{statusCode: number, fieldsErrors?: Array<Any>, message: string}} error - The error object
 * @param {"notification"|"alert"}  as - Show the message as notification or alert
 * @returns {void}
 */
export function showResponseErrorMessage(messageWrapperElement, error, as = "notification") {
    let message;
    switch (error.statusCode) {
        case 422:
            for (const fieldKey in error.fieldsErrors) {
                showError(messageWrapperElement.elements[fieldKey], error.fieldsErrors[fieldKey]);
            }
            return;
        case 404:
            message = "Page ou element non trouvé. Si le problème persiste, veillez contacter le support";
            break;
        case 405:
        case 500:
            message = "Un problème est survenu. Si le problème persiste, veuillez contacter le support";
            break;
        default:
            message = error.message || "Une erreur s'est produite. Si le problème persiste, veuillez contacter le support";
            break;
        }
    showMessage(message, messageWrapperElement, "error", as);
}

export function showChartMessage(message, chartElement, type = false) {
    const canvasElement = chartElement.querySelector('canvas')
    canvasElement.classList.add('hidden');
    showMessage(message, chartElement, type);
}

export function removeChartMessage(chartElement) {
    removeMessage(chartElement)
    const canvasElement = chartElement.querySelector('canvas');
    if (!canvasElement) return;
    canvasElement.classList.remove('hidden');
}

/**
 * Format date
 * @param {string|Date} date - Date to format
 * @returns {string} - Formated date string
 */
export function formatDate(date) {
    const dateObj = new Date(Date.parse(date));
    return `${dateObj.getUTCFullYear()}-${(dateObj.getUTCMonth() + 1).toString().padStart(2, "0")}-${dateObj.getUTCDate().toString().padStart(2, 0)}`;
}

/**
 * Format string
 * @param {string} text - The name string to format
 * @return {string} - The formated string
 */
export function formatName(text) {
    return toTitle(text);
}

/**
 * Uppercase the first character of text
 * @param {string} text - The text to transform
 * @returns {string} - The text with the first letter uppercase
 */
export function toTitle(text) {
    return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

/**
 * Reset form field
 * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} formField - A form field element
 */
export function resetFormField(formField) {
    formField.classList.remove('error', 'form-field-error');
    switch (formField.type) {
        case 'select':
            formField.selectedIndex = 0;
            formField.removeAttribute('selected');
            break;
        case 'radio':
        case 'checkbox':
            formField.checked = false;
            break;
        default:
            formField.value = "";
            break;
    }
}

/**
 * 
 * @param {string} valueKey - The key to use as value when the data is an object or array or object
 * @param {string} labelKey - Key used to retrieve the label value if data is an object or array of object
 * @param {string} placeholder - if provided the first select option is used as placeholder
 * @param {Array<string|number>|Array<object>} data - The data to create select option
 * @param {string} format - Whether to format the label
 * @returns {DocumentFragment} - Document fragment containing the list option HTLMOptionElement
 */
export function createSelectOptions(valueKey, labelKey, placeholder, data, format) {
    const optionsFragment = document.createDocumentFragment();
    let optionElement = document.createElement('option');
    if (placeholder) {
        optionElement.textContent = placeholder;
        optionElement.value = "";
        optionsFragment.appendChild(optionElement);
    }

    let label, value;
    if (data && Array.isArray(data) && data.length) {
        data.forEach((option) => {
            optionElement = document.createElement('option');
            if (valueKey && labelKey) {
                label = option[labelKey];
                value = option[valueKey];
            } else {
                label = value = option;
            }
            if (format) {
                label = format(label);
            }
            optionElement.value = value;
            optionElement.textContent = label;
            optionsFragment.appendChild(optionElement);
        })
    } else if (data) {
        for (const option in data) {
            optionElement = document.createElement('option');
            value = label = data[option];
            if (format) {
                label = format(label);
            }
            optionElement.textContent = label
            optionsFragment.appendChild(optionElement);
        }
    }
    return optionsFragment;
}

/**
 * Set value as selected to html select
 * 
 * @param {HTMLSelectElement} selectElement - The select element
 * @param {string} value - The value to set as selected
 */
export function setSelectedOption(selectElement, value) {
    const selectedOptionElement = Array.from(selectElement.options).find(item => item.value == value) || selectElement.options[1];
    if (selectedOptionElement) {
        selectElement.index = selectedOptionElement.index;
        selectedOptionElement.selected = true;
    }
}

/**
 * Find value from object with key
 * @param {string} propertyName - The name of object property to retrive
 * @param {object} object - The object where to find the property
 * @returns {any}
 */
export function getProperty(propertyName, object) {
    let parts = propertyName.split( "." ),
    length = parts.length,
    i,
    property = object;
  
    for ( i = 0; i < length; i++ ) {
      property = property[parts[i]];
    }
  
    return property;
}

export function afterStatusDialogOpenCallback(message, dialog) {
    const messageElement = dialog.dialogElement.querySelector('.message');
    if (!messageElement) return;
    messageElement.textContent = message;
}

export function openSessionStatusDialog(message) {
    const dialog = aria.getCurrentDialog();
    if (dialog) {
        dialog.replace('session-status-modal-dialog', document.body, null, {
            'afterOpen': afterStatusDialogOpenCallback.bind(null, message)
        })
    } else {
        openDialog('session-status-modal-dialog', document.body, null, {
            'afterOpen': afterStatusDialogOpenCallback.bind(null, message)
        });
    }
}

export function getTableActionButton(target) {
    if (target.localName !== 'svg' && target.localName !== 'button' && target.localName !== 'path') return;
    return target.closest('button');
}